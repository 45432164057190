import '../../styles/pages/index.scss'

import '../components/home-regions-slider';
import '../components/war-slider';

import Splide from '@splidejs/splide';
import generateTabs from '../components/generateTabs'
import '../components/tv-hosts'

window.Splide = Splide;

generateTabs('sidebar-last', { isLoadMore: false });
generateTabs('sidebar-popular', { isLoadMore: false });

const allVideoSliders = document.querySelectorAll('.splide-video-slider');

allVideoSliders.forEach((itm) => {
  const splide = new Splide(itm, {
    type: 'loop',
    perPage: 4,
    perMove: 1,
    pagination: false,
    gap: 32,
    flickPower: 100,
    breakpoints: {
      768: {
        perPage: 2,
      },
      1024: {
        perPage: 3,
        gap: 24,
      },
    }
  });

  splide.mount();
})

const heroesTabsBtns = document.querySelectorAll('.heroes-tabs .heroes-tabs__tab_btn');
const heroesImages = document.querySelectorAll('.tv-hosts__images .tv-hosts__image');

const handleMouseOver = (idx) => {
  heroesImages[idx].classList.add('tv-hosts__image_active');
  heroesTabsBtns[idx].classList.add('heroes-tabs__tab_btn_active');
}

const handleMouseOut = (idx) => {
  heroesImages[idx].classList.remove('tv-hosts__image_active');
  heroesTabsBtns[idx].classList.remove('heroes-tabs__tab_btn_active');
}


heroesImages.forEach((itm, idx) => {
  itm.addEventListener('mouseover', () => handleMouseOver(idx))
  itm.addEventListener('mouseout', () => handleMouseOut(idx))
})

heroesTabsBtns.forEach((itm, idx) => {
  itm.addEventListener('mouseover', () => handleMouseOver(idx))
  itm.addEventListener('mouseout', () => handleMouseOut(idx))
})

// Adding "Читати більше" dynamically on home page
window.addEventListener("load", function () {
  const isTabletOrMob = window.innerWidth <= 768;
  const textElements = document.querySelectorAll(".videos-projects__inner .video-project__description");
  textElements.forEach((textElement, idx) => {
    // 72px - height for three rows
    // 120px - height for five rows
    let textHeight = 72;

    if (!isTabletOrMob && idx === 0) {
      // do nothing for first element of desktop version
      return;
    }

    if (isTabletOrMob && idx === 0) {
      textHeight = 120;
    }

    const fullText = textElement.textContent;
    // Clone the element to measure actual text height
    const clone = textElement.cloneNode(true);
    clone.style.position = "absolute";
    clone.style.visibility = "hidden";
    clone.style.maxHeight = "none"; // Allow full text rendering
    clone.style.maxWidth = `${textElement.clientWidth}px`;
    clone.style.webkitLineClamp = "unset";
    document.body.appendChild(clone);

    // Check if text overflows
    if (clone.clientHeight > textHeight) {
      let truncatedText = fullText;
      const msg = window.glblMessages[window.locale]?.nextArticle;

      // Reduce text character by character until it fits
      while (clone.clientHeight > textHeight) {
        truncatedText = truncatedText.slice(0, -1);
        clone.textContent = truncatedText + `... ${msg}`;
      }

      // Check if the last word is less than 3 characters and remove it if so
      const words = truncatedText.trim().split(/\s+/);
      const lastWord = words[words.length - 1];
      if (lastWord.length < 3) {
        words.pop();
        truncatedText = words.join(' ');
      }

      // Update text with "Read more"
      textElement.innerHTML = `${truncatedText.trimEnd()}... <span>${msg}</span>`;
    }
    document.body.removeChild(clone); // Cleanup
  });
});